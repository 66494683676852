export const changeReasonMap = {
  MANUAL_ADJUST: "后台手动调整",
  RED_PACKET_PUBLISH: "发送红包",
  RED_PACKET_CONSUME: "接收红包",
  RED_PACKET_RETURN: "红包退款退回",
  DAILY_SIGN_IN: "每日签到奖励",
  PROMOTION_REWARD: "活动奖励",
  OTHER: "其他"
}

/**
 * changeReason的描述映射
 */
export const mapChangeReason = (reason) => {
  return changeReasonMap[reason] || reason;
}
