import https from "@/utils/https";
import { ContentType, Method } from "axios-mapper";

export const getPointByMemberId = (memberId) => {
  return https().request(`/point/getPoint/${memberId}`, Method.GET);
}

export const updatePointByMemberId = (memberId, changePoints, changeReason, remark) => {
  return https().request(`/point/updatePoint`, Method.POST, {
    memberId: memberId, changePoints: changePoints, changeReason: changeReason, remark: remark
  }, ContentType.json);
}

/**
 * @param sortType DESC、ASC
 */
export const getPointRecordByMemberId = (memberId, pageNum, pageSize, sortType) => {
  return https().request(`/point/getPointRecords/${memberId}?pageNum=${pageNum}&pageSize=${pageSize}&sortType=${sortType}`, Method.GET);
}
