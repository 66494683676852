<template>
  <el-dialog
    :title="dialogTitle"
    v-model="dialogVisible"
    :width="dialogWidth"
  >
    <div v-if="dialogType === 'operation'">
      <el-row class="mb-4" align="middle">
        <el-col :span="1">
          <el-button
            size="mini"
            icon="el-icon-refresh"
            circle
            @click="loadMemberPoint"
          />
        </el-col>
        <el-col :span="22" style="margin-left: 8px;">
          <div class="text-xl">
            當前積分：{{ memberCurrentPoints !== null ? memberCurrentPoints : 'Loading...' }}
          </div>
        </el-col>
      </el-row>
      <el-divider />
      <el-form label-width="auto">
        <el-form-item label="變動積分">
          <el-input
            v-model="form.changePoints"
            type="number"
            :min="-9999999"
            step="0.01"
            placeholder="加分則正值，減分則負值"
            :precision="2"
          />
        </el-form-item>
        <el-form-item label="備註">
          <el-input
            v-model="form.remark"
            type="textarea"
            rows="4"
            placeholder="輸入備註"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :disabled="memberCurrentPoints === null"
            @click="submitUpdate"
          >
            送出
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dialogType === 'record'">
      <el-table :data="pointRecords" size="mini" v-loading="pointRecordPage.loading">
        <el-table-column prop="afterPoints" label="調整後積分" />
        <el-table-column prop="beforePoints" label="調整前積分" />
        <el-table-column prop="changePoints" label="變動積分" />
        <el-table-column label="變動原因" :min-width="80">
          <template #default="scope">
            {{ mapChangeReason(scope.row.changeReason) }}
          </template>
        </el-table-column>
        <el-table-column label="變動時間" :min-width="120">
          <template #default="scope">
            {{ new Date(scope.row.changeTime).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column prop="changeBy" label="操作人" />
        <el-table-column prop="remark" label="備註" :min-width="230" />
      </el-table>
      <el-pagination
        @current-change="changeRecordPage"
        layout="prev, pager, next"
        :page-size="20"
        :total="pointRecordPage.total"
        :current-page="pointRecordPage.currentPage"
      />
    </div>
  </el-dialog>

  <div>
    <div class="header-container">
      <div class="search">
        <el-input v-model="request.loginName" size="small" style="width: 200px;" placeholder="login name" />
        <el-select
          clearable
          v-model="request.type"
          size="small"
          placeholder="Member Type"
          class="filter-item"
          style="width: 120px;margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.memberType"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-model="request.status"
          size="small"
          placeholder="Member Status"
          class="filter-item"
          style="width: 120px;margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.memberState"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <el-button style="margin-left: 20px" icon="el-icon-search" size="mini" type="success" @click="loadMembers()">
          Search
        </el-button>
        <el-button size="mini" type="warning" @click="resetQuery()">Reset</el-button>
      </div>
    </div>
    <el-card class="box-card" shadow="never" style="margin-top: 40px">
      <template #header>
        <div class="clearfix">
          <span class="role-span">Member List</span>
        </div>
      </template>
      <el-table :data="page.records" ref="table"
                v-loading="page.loading"
                row-key="id"
                size="mini"
                :resizable="true"
                highlight-current-row
                @selection-change="handleSelectionChange"
      >
        <el-table-column prop="loginName" label="Login Name">
          <template #default="scope" v-if="hasPermission(['sys:member:detail'])">
            {{ scope.row.loginName }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="Member Type">
          <template #default="scope">
            <el-tag v-if="scope.row.type === 'USER'" type="success" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'PARTNER'" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'GUEST'" type="info" size="mini">{{ scope.row.type }}</el-tag>
            <el-tag v-if="scope.row.type === 'ROBOT'" size="mini">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 'NORMAL'" type="success" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === 'FROZEN'" type="danger" size="mini">{{ scope.row.status }}</el-tag>
            <el-tag v-if="scope.row.status === null" type="info" size="mini">-</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="point">
          <template #default="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openPointDialog('operation',scope.row)"
            >
              積分操作
            </el-button>
            <el-button
              size="mini"
              type="info"
              @click="openPointDialog('record',scope.row)"
            >
              積分紀錄
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination class="pagination"
                     @current-change="changePage"
                     layout="prev, pager, next"
                     :page-size="request.size"
                     :page-count="page.pages"
                     :current-page="request.current"
      />
    </el-card>
  </div>
</template>

<script setup>

import { reactive, ref, onMounted } from "vue";
import { getMembers } from "../../../../api/member";
import { getPointByMemberId, getPointRecordByMemberId, updatePointByMemberId } from "../../../../api/point";
import { hasPermission } from '../../../../utils/util'
import { ElMessage } from "element-plus";
import { mapChangeReason } from '../../../../utils/pointMapper';

const table = ref(null);
const page = reactive({
  pages: 0,
  records: [],
  loading: false
});

const dialogVisible = ref(false);
const dialogType = ref('');
const dialogTitle = ref('');
const dialogWidth = ref('800px');
const currentMember = ref(null);

const form = ref({
  memberId: null,
  changePoints: null,
  remark: '',
  changeReason: 'MANUAL_ADJUST'
});

const memberCurrentPoints = ref(null);

const pointRecords = ref([]);
const pointRecordPage = ref({
  loading: false,
  currentPage: 1,
  total: 0
});

async function loadMemberPointRecord(pageNum, pageSize) {
  pointRecordPage.value.loading = true;
  try {
    const ret = await getPointRecordByMemberId(currentMember.value.id, pageNum, pageSize, "DESC");
    if (ret.code !== 0) {
      throw new Error(ret.message || '獲取積分紀錄失敗');
    }
    pointRecords.value = ret.data.records;
    pointRecordPage.value.total = ret.data.total;
  } catch (error) {
    ElMessage({
      message: error.message || '加載積分紀錄時出現錯誤',
      type: 'error',
      duration: 3000
    });
  } finally {
    pointRecordPage.value.loading = false;
  }
}

async function openPointDialog(openType, row) {
  dialogType.value = openType;
  currentMember.value = row;

  if (openType === 'operation') {
    form.value.memberId = currentMember.value.id;
    resetFormInput();
    dialogWidth.value = '600px';
    dialogTitle.value = '積分操作';
    memberCurrentPoints.value = null;
    loadMemberPoint();
  } else if (openType === 'record') {
    dialogWidth.value = '1024px';
    dialogTitle.value = '積分歷史紀錄';
    pointRecords.value = [];
    pointRecordPage.value.currentPage = 1;
    loadMemberPointRecord(pointRecordPage.value.currentPage, 20);
  }

  dialogVisible.value = true;
}

function resetFormInput() {
  form.value.changePoints = 0;
  form.value.remark = '';
}

function changeRecordPage(page) {
  pointRecordPage.value.currentPage = page;
  loadMemberPointRecord(page, 20);
}

const uiControl = reactive({
  dialogVisible: false,
  memberState: [
    { key: 1, displayName: "All Status", value: null },
    { key: 2, displayName: "Normal", value: "NORMAL" },
    { key: 3, displayName: "Frozen", value: "FROZEN" }
  ],
  memberType: [
    { key: 1, displayName: "All Types", value: null },
    { key: 2, displayName: "User", value: "USER" },
    { key: 3, displayName: "Partner", value: "PARTNER" },
    { key: 4, displayName: "Guest", value: "GUEST" },
    { key: 5, displayName: "Robot", value: "ROBOT" }
  ],
});

const request = reactive({
  size: 30,
  current: 1,
  loginName: null,
  type: null,
  status: null,
});

function resetQuery() {
  request.loginName = null;
  request.type = null;
  request.status = null;
  uiControl.searchDialogVisible = false;
}

function changePage(page) {
  if (request.current >= 1) {
    request.current = page;
    loadMembers();
  }
}

function checkQuery() {
  const requestCopy = { ...request };
  const query = {};
  Object.entries(requestCopy).forEach(([key, value]) => {
    if (value) {
      query[key] = value;
    }
  });
  return query;
}

async function loadMembers() {
  page.loading = true;
  uiControl.searchDialogVisible = false;
  const query = checkQuery();
  const { data: ret } = await getMembers(query);
  page.pages = ret.pages;
  page.records = ret.records;
  page.loading = false;
  table.value.clearSelection();
}

async function loadMemberPoint() {
  memberCurrentPoints.value = null;
  try {
    const ret = await getPointByMemberId(currentMember.value.id);
    if (ret.code !== 0) {
      throw new Error(ret.message || '載入積分失敗');
    }
    memberCurrentPoints.value = ret.data.totalPoints;
  } catch (error) {
    ElMessage({
      message: '載入積分失敗',
      type: 'error',
      duration: 3000
    });
  }
}

async function submitUpdate() {
  if (form.value.changePoints == null || form.value.changePoints === 0 || form.value.changePoints === '0') {
    ElMessage({
      message: '積分變動不能為0',
      type: 'error',
      duration: 3000,
    });
  } else {
    try {
      const ret = await updatePointByMemberId(form.value.memberId, form.value.changePoints, form.value.changeReason, form.value.remark);
      if (ret.code !== 0) {
        throw new Error(ret.message || '更新積分失敗');
      }
      const data = ret.data;
      memberCurrentPoints.value = data.afterPoints;
      resetFormInput();
      ElMessage({
        message: '更新積分成功',
        type: 'success',
        duration: 3000
      });
    } catch (error) {
      ElMessage({
        message: '更新積分失敗',
        type: 'error',
        duration: 3000
      });
    }
  }
}

onMounted(() => {
  loadMembers();
});

</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-progress--line {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 430px;
}

.el-result {
  padding: 0;
}
</style>
